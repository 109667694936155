import "@/assets/scss/argon-design-system.scss";
import '@/assets/sass/argon.scss';

import "@/assets/vendor/nucleo/css/nucleo-icons.css";
import "@/assets/vendor/font-awesome/css/font-awesome.css";
import "@/assets/vendor/custom/icon_moon.css";

import globalDirectives from "./globalDirectives";
import globalComponents from "./globalComponents";
import globalMixins from "./globalMixins";

// Notifications plugin. Used on Notifications page
import Notifications from '@/components/NotificationPlugin';
import { BootstrapVue } from 'bootstrap-vue'
export default {
  install(Vue) {
    Vue.use(globalDirectives);
    Vue.use(globalComponents);
    Vue.use(globalMixins);
    Vue.use(Notifications);
    Vue.use(BootstrapVue);
  }
};
