import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import  i18n  from './i18n'
import router from './routes/router';
import store from "@/store/index";
//import { VueI18n } from './i18n'
import {BASEURL} from "@/store/constants";

Vue.use(VueAxios, axios);

// export default axios.create({
//   baseURL: process.env.VUE_APP_ROOT_API,
//   headers: {
//     "Content-type": "application/json"
//   }
// });
//console.log("called http common "+i18n.locale);

//const token = localStorage.getItem('user-token')
var setheaders = {
  "Content-type": "application/json",
  "Accept-Language":i18n.locale
};
//if (token) {
//  setheaders = {
//    "Content-type": "application/json",
//    "Authorization":'Bearer '+token
//  };
//}
//timeout: 30000
//var baseurl=window.location.origin+"/calfv3";
//if(process.env.VUE_APP_ENV=="dev"){
//  var baseurl="http://localhost/calfv3/";
//}
//if(process.env.VUE_APP_ENV=="testing"){
//  var baseurl=process.env.VUE_APP_ROOT;
//}
// console.log(baseurl)
const httpClient = axios.create({
  baseURL: BASEURL,
  headers: setheaders
});

const getAuthToken = () => localStorage.getItem('user-token');

const authInterceptor = (config) => {
  const usertoken=getAuthToken();
  if(usertoken){
    config.headers['Authorization'] = 'Bearer '+usertoken;
  }
    return config;
}

//httpClient.interceptors.request.use(function (config) {
//  const usertoken=getAuthToken();
//  //alert('test '+usertoken);
//
//   return config;
//}, function (error) {
//   // Do something with request error
//   return Promise.reject(error)
//})

httpClient.interceptors.request.use(authInterceptor);

httpClient.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    store.dispatch('AUTH_LOGOUT');
    router.push('/home').catch(()=>{});
  }
  return Promise.reject(error)
});

export default httpClient;

//export default axios.create({
//  baseURL: process.env.VUE_APP_ROOT_API,
//  headers: setheaders
//});