
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';

let auth = {
  path: '/',
  component: () => import( /* webpackChunkName: "Marketplace" */ '@/views/Layout/AuthLayout.vue'),
  name: 'AuthMarketplace',
  children: [
    {
      path: '/login',
      name: 'Login',
      component: () => import( /* webpackChunkName: "Login" */ '@/views/Pages/MarketplaceLogin.vue')
    },
    {
      path: '/logout',
      name: 'Logout',
      component: () => import( /* webpackChunkName: "Login" */ '@/views/Pages/MarketplaceLogout.vue')
    },
    {
      path: '/sign-up',
      name: 'Signup',
      component: () => import( /* webpackChunkName: "Login" */ '@/views/Pages/MarketplaceSignUp.vue')
    }
  ]
}

let marketplace = {
  path: '/',
  component: () => import( /* webpackChunkName: "Marketplace" */ '@/views/Layout/Marketplace.vue'),
  name: 'Marketplace',
  children: [

    {
      path: '/home',
      name: 'landingpage',
      component: () => import( /* webpackChunkName: "Home" */ '@/views/Pages/Marketplace/Landingpage.vue'),
    },
    {
      path: '/blog',
      name: 'Blogs',
      component: () => import(/* webpackChunkName: "Blog" */ '@/views/Pages/Marketplace/Blog.vue')
    },
    {
      path: '/blog/:slug',
      name: 'Blog-detail',
      component: () => import(/* webpackChunkName: "Blog" */ '@/views/Pages/Marketplace/BlogDetail.vue')
    },
    {
      path: '/page/:slug',
      name: 'dynamic-pages',
      //component: () => import( /* webpackChunkName: "Pages" */ '@/views/Pages/Marketplace/Dynamicpage.vue'),
      component: () => import( /* webpackChunkName: "Pages" */ '@/views/Pages/Marketplace/Landingpage.vue'),
    },
    {
      path: '/course-library/:c?',
      name: 'course-library',
      component: () => import( /* webpackChunkName: "CourseLibrary" */ '@/views/Pages/Marketplace/Course_search/Search.vue')
    },
    {
      path: '/courses/:slug',
      name: 'course-category-wise',
      //component: () => import( /* webpackChunkName: "Courses" */ '@/views/Pages/Marketplace/CoureCategory.vue'),
      component: () => import( /* webpackChunkName: "Courses" */ '@/views/Pages/Marketplace/Landingpage.vue'),
    },
    {
      path: '/course-detail/:slug/:id',
      name: 'course-details',
      //component: () => import( /* webpackChunkName: "Courses" */ '@/views/Pages/Marketplace/CourseDetail.vue'),
      component: () => import( /* webpackChunkName: "CoursesDetails" */ '@/views/Pages/Marketplace/CourseDetailTemplate2.vue')
    },
    {
      path: '/program/:slug/:id',
      name: 'program',
      component: () => import( /* webpackChunkName: "CoursesDetails" */ '@/views/Pages/Marketplace/CourseDetailTemplate2.vue')
      //component: () => import( /* webpackChunkName: "CoursesDetails" */ '@/views/Pages/Marketplace/ProgramDetail.vue')
    },
    {
      path: '/program-detail/:slug',
      name: 'program-details',
      component: () => import( /* webpackChunkName: "MProgram" */ '@/views/Pages/Marketplace/Program/LandingPage.vue'),
      //component: () => import( /* webpackChunkName: "Courses" */ '@/views/Pages/Marketplace/Landingpage.vue'),
      children: [
        {
          path: 'tab/:pslug',
          name: 'pdetailpage',
          component: () => import( /* webpackChunkName: "MProgram" */ '@/views/Pages/Marketplace/Program/ProgramDetail.vue'),
        }
      ]
    },
    
    {
      path: '/cart',
      name: 'Cart',
      component: () => import( /* webpackChunkName: "Cart" */ '@/views/Pages/Marketplace/Cart.vue')
    },
    {
      path: '/instructor/:id',
      name: 'TutorDetails',
      component: () => import( /* webpackChunkName: "Tutor" */ '@/views/Pages/Marketplace/TutorDetails.vue')
    },
    {
      path: '*',
      component: () => NotFound
    }
  ]
}

// let courses = {
//   path: '/courses',
//   component: () => import( /* webpackChunkName: "AuthLayout" */ '@/views/Pages/AuthLayout.vue'),
//   name: 'Coursepagelayout',
//   children: [

//     {
//       path: '/courses',
//       name: 'Courses',
//       component: () => import( /* webpackChunkName: "Courses" */ '@/views/Pages/Course/Courses.vue')
//     },
//     {
//       path: '/courses/course-Details/:courseid',
//       name: 'Course Details',
//       component: () => import( /* webpackChunkName: "CoursesDetails" */ '@/views/Pages/Course/Course-Details.vue')
//     },
//     {
//       path: '/courses/course-Details/:courseid/Cart',
//       name: 'Cart',
//       component: () => import( /* webpackChunkName: "Cart" */ '@/views/Pages/Course/Cart.vue')
//     },
//   ]
// }



const routes = [

  {
    path: '/themedesign',
    name: 'themedesign',
    component: () => import(/* webpackChunkName: "ThemeDesign" */ '@/views/themedesign/pages/themedesign.vue')
  },
  {
    path: '/cmspreview',
    name: 'cmspreview',
    component: () => import(/* webpackChunkName: "cmspreview" */ '@/views/themedesign/pages/cmspreview.vue')
  },
  {
    path: '/cms-component-preview',
    name: 'cmscomponentpreview',
    component: () => import(/* webpackChunkName: "cmspreview" */ '@/views/themedesign/pages/component_preview.vue')
  },
  {
    path: '/previewpage',
    name: 'Previewpage',
    component: () => import(/* webpackChunkName: "previewpageshow" */ '@/views/themedesign/pages/Previewpage.vue')
  },
  {
    path: '/newdetailspage',
    name: 'Detailspage',
    component: () => import(/* webpackChunkName: "newdetailspage" */ '@/views/themedesign/pages/Newdetailspage.vue')
  },
  {
    path: '/description',
    name: 'Description ',
    component: () => import(/* webpackChunkName: "Description" */ '@/views/themedesign/pages/Description.vue')
  },
  {
    path: '/coursedetails',
    name: 'Coursedetails ',
    component: () => import(/* webpackChunkName: "Coursedetails" */ '@/views/themedesign/pages/Coursedetails.vue')
  },
 
  {
    path: '/',
    redirect: '/home',
    name: 'Home'
  },
  {
    path: '/get-token',
    name: 'GetToken',
    component: () => import( /* webpackChunkName: "GetToken" */ '@/views/Pages/GetToken.vue')
  },
  //tao,
  auth,
  marketplace,
];


export default routes;
