<template>
  <router-view></router-view>
  
</template>

<script>
export default {
   mounted() {
  },
//    metaInfo() {
//         return { 
//             title: "Marketplace",
//             meta: [
//                 { name: 'description', content:  'Marketplace'},
//                 { property: 'og:title', content: "Marketplace"},
//                 { property: 'og:site_name', content: 'CALF'},
//                 {property: 'og:type', content: 'website'},    
//                 {name: 'robots', content: 'index,follow'} 
//             ]
//         }
//     }
}
</script>
<style scoped>


</style>