
import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
//import VueCarousel from 'vue-carousel';
import router from './routes/router';
import Argon from "./plugins/argon-kit";

Vue.use(Argon);
Vue.component(
  'custom-input', () => import('@/components/Custom/Adminside/custominput.vue')
)

//import Donut from 'vue-css-donut-chart';
//import 'vue-css-donut-chart/dist/vcdonut.css';
//import "chart.js";
//import "hchs-vue-charts";
//import Chartkick from "vue-chartkick";
//import Chart from "chart.js";
//Vue.use(ChartJsPluginDataLabels.use(Chart));
//Vue.use(Chartkick.use(Chart));
//Vue.use(window.VueCharts);

//import ChartDataLabels from 'chartjs-plugin-datalabels';
//import AudioVisual from 'vue-audio-visual'
//import 'vue-search-select/dist/VueSearchSelect.css'

//import JwPagination from 'jw-vue-pagination';
//import Paginate from 'vuejs-paginate'
//Vue.component('jw-pagination', JwPagination);
//Vue.component('paginate', Paginate)

import i18n from './i18n'

//import VueStar from 'vue-star'
//Vue.component('VueStar', VueStar)

// import Vuelidate from 'vuelidate'
// Vue.use(Vuelidate)
import 'leaflet/dist/leaflet.css';

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

//var VueScrollTo = require('vue-scrollto');
//Vue.use(VueScrollTo)


import VueSplide from '@splidejs/vue-splide';
Vue.use( VueSplide );


import VueMeta from 'vue-meta'
Vue.use(VueMeta)

//import VuePlyr from "vue-plyr";
//import "vue-plyr/dist/vue-plyr.css";
//Vue.use(VuePlyr);                                                                         

//Vue.use(AudioVisual)

//Vue.use(VueCarousel);
//Vue.use(Donut);
Vue.use(DashboardPlugin);

//import VCalendar from 'v-calendar';
//Vue.use(VCalendar, {
//  componentPrefix: 'vc',  
//});

//import vueEventCalendar from 'vue-event-calendar'
//import '../src/views/style/style.css'
//Vue.use(vueEventCalendar, {locale: 'en', weekStartOn: 1})

import store from "@/store/index";

//import timeme from "@/assets/js/timeme";
//Vue.use(timeme);

import modal from "vue-js-modal";
//import { dispatch } from 'd3';

Vue.config.productionTip = false;
Vue.use(modal, { dialog: true, dynamic: true });

// router setup
// router.beforeEach((to, from, next) => {

//   // use the language from the routing param or default language
//   let language = to.params.lang;
//   if (!language) {
//     language = 'en'
//   }

//   // set the current language for i18n.
//   i18n.locale = language
//   next()
// })

// plugin setup

/* eslint-disable no-new */
// new Vue({
//   el: '#app',
//   render: h => h(App),
//   router,
// });
//import {
//  AUTH_REQUEST
//} from "@/store/actions/auth";

store.dispatch("MARKETPLACE_REQUEST").then(startVue);

const deviceno = localStorage.getItem('user-deviceno')

if(deviceno){
  //console.log("exist "+deviceno);
}else{
  const randomnumber=new Date().valueOf().toString(36) + Math.random().toString(36).substr(2);
  localStorage.setItem("user-deviceno", randomnumber);
}

const token = localStorage.getItem('user-token')
if (token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
  //console.log("from mainjs "+axios.defaults.headers.common['Authorization'])
}else{
  //dispatch(AUTH_REQUEST);
}
function startVue() {
new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
}