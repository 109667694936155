import { USER_REQUEST, USER_ERROR, USER_SUCCESS,SET_CART_ITEMS } from "../actions/user";

import Vue from "vue";
//import axios from "axios";
//import VueAxios from "vue-axios";
//Vue.use(VueAxios, axios);
import http from "@/http-common";

import { AUTH_LOGOUT } from "../actions/auth";
import {GETUSERURL} from "@/store/constants";

const state = { status: "", profile: {},cart:{} };

const getters = {
  getProfile: state => state.profile,
  isProfileLoaded: state => !!state.profile.fname,
  p_certificate_enable: state => !!state.profile.p_certificate_enable,
  p_library_enable: state => !!state.profile.p_library_enable,
  p_survey_enable: state => !!state.profile.p_survey_enable,
  currentRole:state=>state.profile.role_name,
  currentRoleId:state=>state.profile.user_role,
  username: state => state.profile.fname+" "+state.profile.lname,
  user_id: state => state.profile.user_id,
  company_id:state=>state.profile.company_id,
  child_company_id:state=>state.profile.child_company_id,
  user_profile_img:state=>state.profile.profile_pic_small,
  site_lang:state=>state.profile.site_lang,
  company_logo:state=>state.profile.client_logo,
  child_company_logo:state=>state.profile.child_client_logo,
  subscription_enable: state => {
    if(state.profile.subscription_required && state.profile.is_user_subscribed && state.profile.is_user_subscribtion_active){
      return true;
    }else{
      return false;
    }
  },
  subscription_user_enrollment_limit: state => {
    if(state.profile.subscription_required && state.profile.is_user_subscribed && state.profile.is_user_subscribtion_active){
      if(state.profile.enrollment_limit==0){
        return false;
      }else if(state.profile.user_enrollment_count>=state.profile.enrollment_limit){
        return true;
      }else{
        return false;
      }
    }else{
      return true;
    }
  },
  getCartItems: state => state.cart
  
};

const actions = {
  [USER_REQUEST]: ({ commit, dispatch }) => {
    commit(USER_REQUEST);
    http.get(GETUSERURL).then(resp => {
        commit(USER_SUCCESS, resp);
      })
      .catch((err) => {
        commit(USER_ERROR);
        // if resp is unauthorized, logout, to
        dispatch(AUTH_LOGOUT);
      });
  },
  [SET_CART_ITEMS]: ({
    commit,
    dispatch
  }, cart_items) => {
    commit(SET_CART_ITEMS, cart_items);
  },
};

const mutations = {
  [USER_REQUEST]: state => {
    state.status = "loading";
  },
  [USER_SUCCESS]: (state, resp) => {
    state.status = "success";
    Vue.set(state, "profile", resp.data.user);
  },
  [USER_ERROR]: state => {
    state.status = "error";
  },
  [AUTH_LOGOUT]: state => {
    state.profile = {};
  },
  [SET_CART_ITEMS]: (state, resp) => {
    Vue.set(state, "cart", resp);
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
