import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import store from "@/store/index";
Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  /*scrollBehavior: (to, from ,savedPosition) => {
    //if (savedPosition) {
    //  return savedPosition;
    //}
    //if (to.hash) {
    //  return { selector: to.hash };
    //}
    return { x: 0, y: 0 };
  }*/
  scrollBehavior(to, from, savedPosition) {
    //if (savedPosition) {
    //  return savedPosition;
    //} else {
      const position = {};
      if (to.hash) {
        position.selector = to.hash;
        position.behavior = 'smooth';
        if (document.querySelector(to.hash)) {
          return position;
        }

        return false;
      }
      return { x: 0, y: 0 };
    //}
  }
});


//router.beforeEach((to, from, next) => {
//  if (to.matched.some(record => record.meta.requiresAuth)) {
//    let authenticated = store.getters.isAuthenticated;
//    if (!authenticated) {
//      next({
//        name: "Login"
//      });
//    } else {
//      next();
//    }
//  } else {
//    next();
//  }
//});

export default router;
