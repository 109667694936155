// Polyfills for js features used in the Dashboard but not supported in some browsers (mainly IE)
import "@/polyfills";
// Notifications plugin. Used on Notifications page
import Notifications from "@/components/NotificationPlugin";

// A plugin file where you could register global components used across the app
import GlobalComponents from "./globalComponents";
// A plugin file where you could register global directives
import GlobalDirectives from "./globalDirectives";

import globalMixins from "./globalMixins";

// Sidebar on the right. Used as a local plugin in DashboardLayout.vue
import SideBar from "@/components/SidebarPlugin";

// element ui language configuration
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
locale.use(lang);

import "@/assets/scss/argon-design-system.scss";
import "@/assets/vendor/font-awesome/css/font-awesome.css";
import "@/assets/vendor/custom/icon_moon.css";

// vue-bootstrap //, IconsPlugin
import { BootstrapVue } from "bootstrap-vue";

// asset imports
import "@/assets/sass/argon.scss";
import "@/assets/css/nucleo/css/nucleo.css";

// Validation plugin used to validate forms
import { configure } from "vee-validate";
import { extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/en.json";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  });
});
export default {
  install(Vue) {
    Vue.use(GlobalComponents);
    Vue.use(GlobalDirectives);
    Vue.use(globalMixins);
    Vue.use(SideBar);
    Vue.use(Notifications);
    Vue.use(BootstrapVue);
    Vue.use(Toast);
    //Vue.use(IconsPlugin);
    configure({
      classes: {
        valid: "is-valid",
        invalid: "is-invalid",
        dirty: ["is-dirty", "is-dirty"] // multiple classes per flag!
      }
    });
  }
};
