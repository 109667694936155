import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import auth from "./modules/auth";
import cms from "./modules/cms";


import createPersistedState from 'vuex-persistedstate'
import createMultiTabState from 'vuex-multi-tab-state';
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  namespaced: true,
  modules: {
    user,
    auth,
    cms
  },
  strict: debug,
  plugins: [
    createPersistedState({paths:['auth','user','cms']}),
    createMultiTabState()
  ]

});
