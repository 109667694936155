var baseurl_api=window.location.origin+"/api/v1/";
var baseurl=window.location.origin+"/";
if(process.env.VUE_APP_ENV=="dev"){
    var baseurl_api="http://localhost/calfv3/api/v1/";
    var baseurl="http://localhost/calfv3/";
}
if(process.env.VUE_APP_ENV=="testing"){
    var baseurl_api=process.env.VUE_APP_ROOT_API;
    var baseurl=process.env.VUE_APP_ROOT;
}
export const BASEURL = baseurl;
export const BASEURL_API = baseurl_api;

export const USER_UPDATE_COUNTRY = baseurl_api+"user-update-country";

export const MARKETPLACE_CONFIG = baseurl_api+"marketplace-config";
export const GET_GENERAL_CMS_THEME_SETTINGS = baseurl_api+"marketplace-cms-theme-settings";
export const SAVE_GENERAL_CMS_THEME_SETTINGS = baseurl_api+"marketplace-cms-theme-settings-update";

export const COPY_COMPONENT_TO_PAGE = baseurl_api+"copy-component-to-page";

export const GET_OTHER_BATCHES = baseurl_api+"batch-filter";
export const GET_INSTRUCTOR_DETAILS = baseurl_api+"get-instructor-details";

export const GET_SUBSCRIPTION = baseurl_api+"get-subscription";
export const GET_CATEGORIES = baseurl_api+"get-categories";

export const SAVE_CART_ITEM = baseurl_api+"add-item-to-cart";
export const GET_CART_ITEMS = baseurl_api+"get-cart-items";
export const REMOVE_CART_ITEM = baseurl_api+"remove-cart-item";
export const APPLY_COUPON_FOR_ITEM = baseurl_api+"apply-coupon-to-item";

export const LOGIN = baseurl+"auth/marketplace_login_api";
export const LOGOUT = baseurl+"auth/marketplace_logout_api";
export const SIGNUP_CHECK = baseurl+"auth/marketplace_signup_check_api";
export const SIGNUP = baseurl+"auth/marketplace_signup_api";
export const FORGOT_PASSWORD = baseurl+"auth/marketplace_forgot_password_api";

export const LOGINURL = baseurl_api+"auth/get-token";
export const LOGOUTURL = baseurl_api+"auth/logout";
export const GETUSERURL = baseurl_api+"user/me";

export const GET_PARTICIPANT_COURSE_STATUS = baseurl_api+"participant-course-status";
export const GET_PARTICIPANT_INPROGRESS_COURSE = baseurl_api+"participant-inprogress-course";
export const GET_PARTICIPANT_FEATURED_COURSE = baseurl_api+"participant-featured-course";
export const GET_PARTICIPANT_LEADERBOARD = baseurl_api+"participant-leaderboard";
export const GET_PARTICIPANT_CERTIFICATE = baseurl_api+"participant-certificates";
export const GET_PARTICIPANT_MYJOURNEY = baseurl_api+"participant-myjourney";
export const GET_PARTICIPANT_CALENDAR = baseurl_api+"participant-calendar";

export const GET_SIGNED_URL = baseurl_api+"get-signed-url";
export const GET_POSTS = baseurl_api+"get-posts";
export const CREATE_POSTS = baseurl_api+"create-post";
export const UPDATE_POST = baseurl_api+"update-post";
export const REPORT_ABUSE_POSTS = baseurl_api+"report-abuse-post";
export const LIKE_POST = baseurl_api+"like-post";
export const PARTICIPANT_COURSES = baseurl_api+"participant-course-post";
export const REMOVE_POST = baseurl_api+"remove-post";
export const COMMENT_POST = baseurl_api+"comment-post";
export const UPDATE_COMMENT_POST = baseurl_api+"update-post-comment";
export const COMMENT_REPLY_POST = baseurl_api+"comment-reply-post";
export const COMMENT_DELETE_POST = baseurl_api+"comment-delete-post";

export const GET_ANNOUNCEMENTS = baseurl_api+"participant-announcement-list";

export const GET_PROGRAM = baseurl_api+"participant-program-details";

export const UPDATE_PROFILE_DATA = baseurl_api+"update-personal-details";
export const UPDATE_PASSWORD_DATA = baseurl_api+"update-password";
export const UPDATE_PROFILE_IMG_DATA = baseurl_api+"update-profile-picture";
export const UPDATE_PROFILE_DETAILS_DATA = baseurl_api+"update-profile-details";
export const UPDATE_SOCIAL_MEDIA_DATA = baseurl_api+"update-social-media-details";
export const GET_USER_DETAILS = baseurl_api+"get-user-details";



export const GET_PARTICIPANT_COURSE_LIST = baseurl_api+"participant-course-list";
export const GET_PARTICIPANT_COURSE_DETAILS = baseurl_api+"participant-course-details";

export const GET_COURSE_VIDEO = baseurl_api+"coursemodule-video";
export const SAVE_COURSE_VIDEO = baseurl_api+"coursemodule-save-video-time";

export const GET_COURSE_REVIEW = baseurl_api+"course-review-list";
export const SAVE_COURSE_REVIEW = baseurl_api+"create-course-review";

export const COURSE_AGREEMENT = baseurl_api+"course-agreement";
export const SAVE_COURSE_AGREEMENT = baseurl_api+"course-agreement-save";

export const GET_SURVEY_SCALE = baseurl_api+"coursemodule-survey-likert-config";
export const GET_COURSE_SURVEY = baseurl_api+"coursemodule-survey";
export const SAVE_COURSE_SURVEY = baseurl_api+"coursemodule-insert-survey";

export const GET_COURSE_AUDIO = baseurl_api+"coursemodule-audio";
export const SAVE_COURSE_AUDIO = baseurl_api+"coursemodule-save-audio-time";

export const GET_COURSE_SCORM = baseurl_api+"coursemodule-scorm";
export const GET_COURSE_ATTACHMENT = baseurl_api+"coursemodule-attachment";

export const GET_NOTIFICATION_LIST = baseurl_api+"get-participant-notifications";
export const GET_NOTIFICATION_COUNT = baseurl_api+"participant/get-notification-announcement-count";

export const GET_SUPPORT_TICKET = baseurl_api+"support-ticket-list";
export const CREATE_SUPPORT_TICKET = baseurl_api+"support-ticket-create";

export const GET_CERTIFICATE_LIST = baseurl_api+"participant-certificates";

export const GET_NOTES = baseurl_api+"get-user-notes";
export const CREATE_NOTE = baseurl_api+"create-user-notes";
export const UPDATE_NOTE = baseurl_api+"update-user-notes";
export const DELETE_NOTE = baseurl_api+"delete-user-notes";

export const GET_COURSE_ASSESSMENT = baseurl_api+"coursemodule-assessment-components";
export const START_COURSE_ASSESSMENT = baseurl_api+"coursemodule-start-assessment-attempt";
export const COURSE_ASSESSMENT_NEW_ATTEMPT = baseurl_api+"coursemodule-new-assessment-attempt";
export const GET_COURSE_ASSESSMENT_OVERVIEW = baseurl_api+"coursemodule-assessment-overview";
export const SAVE_COURSE_ASSESSMENT = baseurl_api+"coursemodule-assessment-save";
export const SAVE_COURSE_ASSESSMENT_TIMESPENT = baseurl_api+"coursemodule-assessment-time-spend-updation";
export const SAVE_COURSE_ASSESSMENT_TIMER = baseurl_api+"coursemodule-assessment-timer-save-info";

export const SAVE_ASSESSMENT_PROCTORING = baseurl_api+"coursemodule-assessment-proctoring-save";

export const SAVE_COURSE_MODULE_TIMESPENT = baseurl_api+"course-module-time-spent";

export const GET_COURSE_LAP = baseurl_api+"coursemodule-lap";
export const GET_COURSE_METRIC = baseurl_api+"coursemodule-metric";

export const GET_COURSE_ASSIGNMENT = baseurl_api+"coursemodule-assignment";
export const SAVE_COURSE_ASSIGNMENT = baseurl_api+"course-assignment-upload";
export const SUBMIT_COURSE_ASSIGNMENT = baseurl_api+"course-assignment-submit";
export const WITHDRAW_COURSE_ASSIGNMENT = baseurl_api+"coursemodule-assignment-withdraw";

export const GET_COURSE_HTMLPAGE = baseurl_api+"coursemodule-pagehtml";

export const GET_COURSE_EXTERNALLINK = baseurl_api+"coursemodule-externallink";
export const SAVE_COURSE_EXTERNALLINK = baseurl_api+"coursemodule-externallink-attend";

export const SAVE_COURSE_ATTACHMENT = baseurl_api+"coursemodule-attachment-attend";



export const GET_MENU = baseurl_api+"get-menus";
export const SAVE_PAGE_TO_MENU = baseurl_api+"add-page-to-menu";
export const REMOVE_PAGE_FROM_MENU = baseurl_api+"remove-page-from-menu";
export const REORDER_ITEM_IN_MENU = baseurl_api+"reorder-item-in-menu";

export const GET_LIBRARY_COURSES = baseurl_api+"library-courses";
export const GET_COURSE_FROM_IDS = baseurl_api+"get-courses-from-ids";
export const GET_CATEGORY_FROM_IDS = baseurl_api+"get-category-from-ids";

export const COMPONENT_REORDER = baseurl_api+"page-components-reorder";
export const GET_MARKETPLACE_COURSES = baseurl_api+"get-marketplace-courses";

export const GET_PAGE_COMPONENTS = baseurl_api+"page-components";
export const GET_ALL_COMPONENTS = baseurl_api+"all-components";
export const SAVE_PAGE_COMPONENTS = baseurl_api+"add-page-component";
export const UPDATE_COMPONENT_DETAILS = baseurl_api+"update-page-component";
export const REMOVE_PAGE_COMPONENT = baseurl_api+"remove-page-component";
export const PUBLISH_PAGE_COMPONENT = baseurl_api+"publish-page-component";
export const GET_NAVBAR_DETAILS = baseurl_api+"get-navbar-details";
export const GET_THEMEDESIGN = baseurl_api+"themedesigner-settings";
export const CREATE_PAGE = baseurl_api+"create-page";
export const UPDATE_PAGE = baseurl_api+"update-page";

export const GET_NAVBARFOOTER_DETAILS = baseurl_api+"get-navbarfooter-details";
export const GET_PAGE_COMPONENTS_DETAILS = baseurl_api+"page-components-details";
export const GET_PAGE_DETAILS = baseurl_api+"page-details";
export const VALIDATE_PAGE = baseurl_api+"validate-page";

export const GET_LANGUAGE_LIST = baseurl_api+"language_list";

export const GET_BLOG_LIST = baseurl_api+"blog_list";
export const GET_BLOG_DETAILS = baseurl_api+"blog_details";
export const GET_CATEGORY_COURSES = baseurl_api+"courses";
export const GET_COURSE_DETAILS = baseurl_api+"course-details";
export const GET_PROGRAM_COURSE_DETAILS = baseurl_api+"program-course-details";

export const GET_SIGN_IN = baseurl_api+"sign-in-page";
export const GET_SIGN_UP = baseurl_api+"sign-up-page";

export const GET_LOGIN_PAGE = baseurl_api+"login-page";
export const SAVE_NEWSLETTER_SIGNUP = baseurl_api+"add-newsletter-subscription";


export const GET_FILE_MANAGER_LIST = baseurl_api+"filemanager/getlist";
export const UPLOAD_FILE_MANAGER_FILE = baseurl_api+"filemanager/upload";
export const CREATE_FILE_MANAGER_FOLDER = baseurl_api+"filemanager/new_folder";


export const Save_Complaint_form = baseurl_api+"create-complaint";
export const Save_Non_Complaint_form = baseurl_api+"create-non-conformance";

export const GET_GROUP_POSTS = baseurl_api+"get-group-post-list";
export const CREATE_GROUP_POSTS = baseurl_api+"create-group-post";
export const UPDATE_GROUP_POST = baseurl_api+"update-group-post";
export const REPORT_ABUSE_GROUP_POSTS = baseurl_api+"report-abuse-group-post";
export const LIKE_GROUP_POST = baseurl_api+"like-group-post";
export const REMOVE_GROUP_POST = baseurl_api+"remove-group-post";
export const COMMENT_GROUP_POST = baseurl_api+"group-post-comment";
export const UPDATE_COMMENT_GROUP_POST = baseurl_api+"update-group-post-comment";
export const COMMENT_REPLY_GROUP_POST = baseurl_api+"comment-reply-post";
export const COMMENT_DELETE_GROUP_POST = baseurl_api+"remove-group-post-comment";
// ---- Admin side---------
export const GET_COUSRES_DATA = baseurl_api+"";
export const CREATE_COUSRES = baseurl_api+"";
export const UPDATE_COUSRES = baseurl_api+"";

export const GET_PROGRAM_DATA = baseurl_api+"";
export const CREATE_PROGRAM = baseurl_api+"";
export const UPDATE_PROGRAM = baseurl_api+"";

export const GET_SURVEYS_DATA = baseurl_api+"";
export const CREATE_SURVEYS = baseurl_api+"";
export const UPDATE_SURVEYS = baseurl_api+"";

export const GET_ASSESSMENT_DATA = baseurl_api+"";
export const CREATE_ASSESSMENT = baseurl_api+"";
export const UPDATE_ASSESSMENT = baseurl_api+"";

export const GET_CASE_STUDY_DATA = baseurl_api+"";
export const CREATE_CASE_STUDY = baseurl_api+"";
export const UPDATE_CASE_STUDY = baseurl_api+"";

export const GET_USER_DATA = baseurl_api+"";
export const CREATE_USER = baseurl_api+"";
export const UPDATE_USER = baseurl_api+"";
// ---- User Settings---------
export const GET_REGION_DATA = baseurl_api+"";
export const CREATE_REGION = baseurl_api+"";
export const UPDATE_REGION_DATA = baseurl_api+"";
export const IMPORT_REGION = baseurl_api+"";

export const GET_DEPARTMENT_DATA = baseurl_api+"";
export const CREATE_DEPARTMENT = baseurl_api+"";
export const UPDATE_DEPARTMENT_DATA = baseurl_api+"";
export const IMPORT_DEPARTMENT = baseurl_api+"";

export const GET_BATCH_DATA = baseurl_api+"";
export const CREATE_BATCH = baseurl_api+"";
export const UPDATE_BATCH_DATA = baseurl_api+"";

export const GET_GROUPS_DATA = baseurl_api+"";
export const CREATE_GROUPS = baseurl_api+"";
export const UPDATE_GROUPS_DATA = baseurl_api+"";
export const ADD_GROUPS_USER = baseurl_api+"";

export const GET_JOBROLE_DATA = baseurl_api+"";
export const CREATE_JOBROLE = baseurl_api+"";
export const UPDATE_JOBROLE_DATA = baseurl_api+"";
export const IMPORT_JOBROLE = baseurl_api+"";

export const GET_ADDITIONAL_FIELDS_DATA = baseurl_api+"";
export const CREATE_ADDITIONAL_FIELDS_DATA = baseurl_api+"";
export const UPDATE_ADDITIONAL_FIELDS_DATA= baseurl_api+"";

export const RESET_USER_PASSWORD = baseurl_api+"";
export const GET_USER_LIST = baseurl_api+"";

export const GET_GRADES_DATA = baseurl_api+"";
export const CREATE_GRADES = baseurl_api+"";
export const UPDATE_GRADES_DATA = baseurl_api+"";
export const IMPORT_GRADES = baseurl_api+"";
// ---- Course Settings---------
export const GET_BADGES_DATA = baseurl_api+"";
export const CREATE_BADGES_DATA = baseurl_api+"";
export const UPDATE_BADGES_DATA= baseurl_api+"";

export const GET_SCORM_DATA = baseurl_api+"";
export const IMPORT_SCORM_DATA = baseurl_api+"";
export const UPDATE_SCORM_DATA= baseurl_api+"";

export const GET_CERTIFICATES_DATA = baseurl_api+"";
export const CREATE_CERTIFICATES_DATA = baseurl_api+"";
export const UPDATE_CERTIFICATES_DATA= baseurl_api+"";
// ---- Others---------
export const GET_THEME_DATA = baseurl_api+"";
export const UPDATE_THEME_DATA= baseurl_api+"";
export const GET_EMAIL_DATA = baseurl_api+"";
export const UPDATE_EMAIL_DATA= baseurl_api+"";

export const SAVE_CONTACT_US_FORM= baseurl_api+"save-contact-us-form";



