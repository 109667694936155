import {
  CMS_REQUEST,
  CMS_ERROR,
  CMS_SUCCESS,
  CMS_REFRESH,
  CMS_SET_LANGUAGELIST,
  CMS_SET_ACTIVE_PAGE,
  CMS_SET_ACTIVE_PAGE_COMPONENT,
  CMS_SET_ACTIVE_PAGE_COMPONENT_STATUS,
  CMS_SET_ACTIVE_PAGE_COMPONENT_ID,
  MARKETPLACE_REQUEST,
  MARKETPLACE_SUCCESS,
  MARKETPLACE_ERROR,
  LOGIN_SINGUP_OPEN,
  CMS_SET_COPY_COMPONENT_STATUS
} from "../actions/cms";

import Vue from 'vue'
import Vuex from 'vuex'
import http from "@/http-common";
Vue.use(Vuex)

import {
  GET_ALL_COMPONENTS,
  MARKETPLACE_CONFIG
} from "@/store/constants";

var deviceno = localStorage.getItem('user-deviceno')
if(deviceno){
  //console.log("cms exist "+deviceno);
}else{
  var deviceno=new Date().valueOf().toString(36) + Math.random().toString(36).substr(2);
  localStorage.setItem("user-deviceno", deviceno);
}

const state = {
  status: "",
  cmsEditMode: false,
  cmsRefresh: false,
  activePage: null,
  activePageId:0,
  activePageComponentId: null,
  activePageComponent: null,
  activePageComponentStatus: false,
  allComponents: [],
  language_list: [],
  current_lang:1,
  csrftoken: "",
  csrfname: "",
  companyid: "",
  childcompanyid: "",
  device_no:deviceno,
  cmsSettings:[],
  app_lang:[],
  tax_type:'inclusive',
  login_singup_open:"page",
  copyComponentStatus: false,  
  app_name:"Marketplace"
}

const getters = {
  getAppName: state => {
    return state.app_name;
  },
  getLoginSingupOpen: state => {
    return state.login_singup_open;
  },
  getCMSTaxType: state => {
    return state.tax_type;
  },
  getCMSAppLang: state => {
    return state.app_lang;
  },
  getCMSCompanyId: state => {
    return state.companyid;
  },
  getCMSChildCompanyId: state => {
    return state.childcompanyid;
  },
  getCMSCsrfName: state => {
      return state.csrfname;
    },
  getCMSCsrfToken: state => {
      return state.csrftoken;
  },
  getCMSDeviceNo: state => {
    return state.device_no;
  },
  getCMSState: state => {
    return state.cmsEditMode;
  },
  getCMSSettings:state=>{
    return state.cmsSettings;
  },
  getPageComponentState: state => {
    return state.activePageComponentStatus;
  },
  getActivePage: state => {
    return state.activePage;
  },
  getActivePageId: state => {
    return state.activePageId;
  },
  getActivePageComponent: state => {
    return state.activePageComponent;
  },
  getActivePageComponentId: state => {
    return state.activePageComponentId;
  },
  getLanguageList: state => {
    return state.language_list;
  },
  getAllComponents: state => {
    return state.allComponents;
  },
  getCurrentLanguage: state => {
    return state.current_lang;
  },
  getCMSRefresh:state=>{
    return state.cmsRefresh;
  },
  getCopyComponentState: state => {
    return state.copyComponentStatus;
  },
  getlevelfilters: state => {
    return state.app_lang.level_filter;
  },
};

const actions = {
  [MARKETPLACE_REQUEST]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit(MARKETPLACE_REQUEST);
      http.get(MARKETPLACE_CONFIG)
        .then(resp => {
          if(resp.status){
            commit(MARKETPLACE_SUCCESS, resp);
            resolve(resp);
          }else{
            commit(MARKETPLACE_ERROR);
          }
          
        })
        .catch(err => {
          commit(MARKETPLACE_ERROR, err);
          reject(err);
        });
      });
  },
  [CMS_REQUEST]: ({
    commit,
    dispatch
  },cmsstate) => {
    commit(CMS_REQUEST,cmsstate);
    http.get(GET_ALL_COMPONENTS).then(resp => {
        commit(CMS_SUCCESS, resp);
      })
      .catch((err) => {
        commit(CMS_ERROR);
      });
  },
  [CMS_SET_LANGUAGELIST]: ({
    commit,
    dispatch
  }, language) => {
    commit(CMS_SET_LANGUAGELIST, language);
  },
  [CMS_SET_ACTIVE_PAGE]: ({
    commit,
    dispatch
  }, page) => {
    commit(CMS_SET_ACTIVE_PAGE, page);
  },
  [CMS_SET_ACTIVE_PAGE_COMPONENT]: ({
    commit,
    dispatch
  }, page) => {
    commit(CMS_SET_ACTIVE_PAGE_COMPONENT, page);
  },
  [CMS_SET_ACTIVE_PAGE_COMPONENT_ID]: ({
    commit,
    dispatch
  }, page) => {
    commit(CMS_SET_ACTIVE_PAGE_COMPONENT_ID, page);
  },
  [CMS_SET_ACTIVE_PAGE_COMPONENT_STATUS]: ({
    commit,
    dispatch
  }, page) => {
    commit(CMS_SET_ACTIVE_PAGE_COMPONENT_STATUS, page);
  },
  [CMS_SET_COPY_COMPONENT_STATUS]: ({
    commit,
    dispatch
  }, page) => {
    commit(CMS_SET_COPY_COMPONENT_STATUS, page);
  },
  
  [CMS_REFRESH]: ({
    commit,
    dispatch
  }, page) => {
    commit(CMS_REFRESH, page);
  },
  [LOGIN_SINGUP_OPEN]: ({
    commit,
    dispatch
  }, page) => {
    commit(LOGIN_SINGUP_OPEN, page);
  },
};

const mutations = {
  [MARKETPLACE_SUCCESS]: (state, resp) => {
    state.status = "success";
    state.csrftoken = resp.data.csrf_token;
    state.companyid = resp.data.company_id;
    state.childcompanyid = resp.data.child_company_id;
    state.csrfname = resp.data.csrf_name;
    state.cmsSettings = resp.data.cmssettings;
    state.app_lang = resp.data.app_lang;
    state.tax_type = resp.data.tax_type;
    state.app_name=resp.data.application_name;
  },
  [MARKETPLACE_REQUEST]: state => {
    state.status = "loading";
  },
  [MARKETPLACE_ERROR]: state => {
    state.status = "error";
  },
  [CMS_REQUEST]: (state,resp) => {
    state.status = "loading";
    Vue.set(state, "cmsEditMode", resp);
  },
  [CMS_SUCCESS]: (state, resp) => {
    state.status = "success";
    Vue.set(state, "allComponents", resp.data);
  },
  [CMS_ERROR]: state => {
    state.status = "error";
  },
  [CMS_SET_LANGUAGELIST]: (state, resp) => {
    Vue.set(state, "language_list", resp);
  },
  [CMS_SET_ACTIVE_PAGE]: (state, resp) => {
    Vue.set(state, "activePage", resp);
    Vue.set(state, "activePageId", resp.id);
  },
  [CMS_SET_ACTIVE_PAGE_COMPONENT]: (state, resp) => {
      Vue.set(state, "activePageComponent", resp);
  },
  [CMS_SET_ACTIVE_PAGE_COMPONENT_ID]: (state, resp) => {
    Vue.set(state, "activePageComponentId", resp);
},
[CMS_SET_ACTIVE_PAGE_COMPONENT_STATUS]: (state, resp) => {
  Vue.set(state, "activePageComponentStatus", resp);
},
[CMS_SET_COPY_COMPONENT_STATUS]: (state, resp) => {
  Vue.set(state, "copyComponentStatus", resp);
},
[CMS_REFRESH]: (state, resp) => {
  Vue.set(state, "cmsRefresh", resp);
},
[LOGIN_SINGUP_OPEN]: (state, resp) => {
  Vue.set(state, "login_singup_open", resp);
},

};

export default {
  state,
  getters,
  actions,
  mutations
};
