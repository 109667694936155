import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT
} from "../actions/auth";
import { USER_REQUEST } from "../actions/user";
//import Vue from "vue";
//import axios from "axios";
//import VueAxios from "vue-axios";
//Vue.use(VueAxios, axios);
import {LOGINURL,LOGOUTURL} from "@/store/constants";

import http from "@/http-common";
const state = {
  token: localStorage.getItem("user-token") || "",
  status: "",
  hasLoadedOnce: false
};

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  getToken: state => state.token,
};

const actions = {
   [AUTH_REQUEST]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
       http.get(LOGINURL)
        .then(resp => {
          if(resp.status){
            localStorage.setItem("user-token", resp.data.token);
            //axios.defaults.headers.common['Authorization'] = 'Bearer '+resp.data.token;
            //console.log("from login "+axios.defaults.headers.common['Authorization']);
            commit(AUTH_SUCCESS, resp);
            dispatch(USER_REQUEST);
            resolve(resp);
          }else{
            commit(AUTH_ERROR);
          localStorage.removeItem("user-token");
          }
          
        })
        .catch(err => {
          commit(AUTH_ERROR, err);
          localStorage.removeItem("user-token");
          reject(err);
        });
      });
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise(resolve => {
      commit(AUTH_LOGOUT);
      http.get(LOGOUTURL)
        .then(resp => {
          localStorage.removeItem("user-token");
        })
        .catch(err => {
        });
      
      resolve();
    });
  }
};

const mutations = {
  [AUTH_REQUEST]: state => {
    state.status = "loading";
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = "success";
    state.token = resp.data.token;
    state.hasLoadedOnce = true;
  },
  [AUTH_ERROR]: state => {
    state.status = "error";
    state.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: state => {
    state.token = "";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
