export const CMS_REQUEST = "CMS_REQUEST";
export const CMS_SUCCESS = "CMS_SUCCESS";
export const CMS_ERROR = "CMS_ERROR";
export const CMS_SET_LANGUAGELIST = "CMS_SET_LANGUAGELIST";
export const CMS_SET_ACTIVE_PAGE = "CMS_SET_ACTIVE_PAGE";
export const CMS_SET_ACTIVE_PAGE_COMPONENT = "CMS_SET_ACTIVE_PAGE_COMPONENT";
export const CMS_SET_ACTIVE_PAGE_COMPONENT_ID = "CMS_SET_ACTIVE_PAGE_COMPONENT_ID";
export const CMS_SET_ACTIVE_PAGE_COMPONENT_STATUS = "CMS_SET_ACTIVE_PAGE_COMPONENT_STATUS";
export const CMS_REFRESH = "CMS_REFRESH";
export const MARKETPLACE_REQUEST = "MARKETPLACE_REQUEST";
export const MARKETPLACE_SUCCESS = "MARKETPLACE_SUCCESS";
export const MARKETPLACE_ERROR = "MARKETPLACE_ERROR";
export const LOGIN_SINGUP_OPEN = "LOGIN_SINGUP_OPEN";
export const CMS_SET_COPY_COMPONENT_STATUS = "CMS_SET_COPY_COMPONENT_STATUS";